<script setup lang="ts">
import type { GamesPayload } from "@/types";

const { t } = useT();
const { open } = useAppModals();
const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const { handleOpenGame } = useOpenGame(open);

const props = defineProps<{
	banner: GamesPayload["gameOfWeek"];
}>();

const handleBannerBtnClick = () => {
	handleOpenGame(props.banner?.slug);
};
</script>

<template>
	<ABanner
		v-if="banner?.background"
		class="banner"
		:background="banner.background"
		:sources="[
			{
				srcset: `${baseImageUrl}${banner.imageBanner2x}`,
				height: 402,
				media: '(max-width: 768px)'
			}
		]"
		:img-attrs="{
			src: `${baseImageUrl}${banner.imageBanner}`,
			alt: 'banner',
			format: 'webp',
			height: 402
		}"
	>
		<div class="banner-content">
			<AText class="text-coro title" variant="toledo" as="div">
				{{ t("Game Of The Week") }}
			</AText>
			<AText class="text-coro sub-title" variant="tunja" :modifiers="['bold']" as="div">
				{{ banner.bannerTitle }}
			</AText>
			<AAnimationCustomByScroll animationClass="blink">
				<AButton size="xl" class="button-link" variant="primary" @click="handleBannerBtnClick">
					<AText variant="turin" :modifiers="['medium']">{{ banner.buttonText }}</AText>
					<ASvg name="30/play" class="icon" />
				</AButton>
			</AAnimationCustomByScroll>
		</div>
	</ABanner>
	<AAnimationSkeleton v-else class="skeleton-banner"><ASkeleton width="100%" height="332px" /></AAnimationSkeleton>
</template>

<style scoped lang="scss">
.skeleton-banner {
	--a-skeleton-border-radius: 24px;
}
.banner {
	position: relative;
	:deep(picture) {
		width: 100%;

		@media (max-width: 400px) {
			height: 100%;
		}
	}
	:deep(img) {
		width: 100%;
	}
	&-content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 24px;
		display: flex;
		flex-direction: column;
		color: var(--cannes);
		gap: 4px;
		justify-content: center;
		@include media-breakpoint-down(md) {
			padding: 24px 16px;
			justify-content: flex-end;
		}
		.sub-title {
			@include media-breakpoint-down(md) {
				font-size: 32px;
			}
		}
		.button-link {
			padding: 0 24px;
			width: max-content;
			gap: 8px;
			transition: all 200ms ease-in-out;
			margin-top: 16px;
			@include media-breakpoint-down(md) {
				margin-top: gutter(1.875);
				height: 48px;
				padding: 0 16px;

				span {
					font-size: 16px;
				}
			}

			.icon {
				font-size: 26px;
			}
		}
	}
}
</style>
